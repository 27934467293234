
import { Link } from "react-router-dom";
import logo from "../assets/img/logo/Final_CRDS Logo_v2.png";
import StyledNavbar from "./StyledNavbar";


function Header() {
  return (
    <>
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <img
              src={logo}
              width="110"
              height="60"
              className="d-inline-block align-top"
              alt=" logo"
            />
            <h2 className="topHeader">
              <Link className="navLink" to="/">
                <b> Center for Regional Development Studies</b>
              </Link>
              <Link className="navLink" to="/">
                <br />
                Bangladesh University of Engineering and Technology (BUET)
              </Link>
            </h2>
          </div>
        </div>
      </section>
    <StyledNavbar />
    </>
  );
}

export default Header;
