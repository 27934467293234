import { Link } from "react-router-dom";
import logo from "../assets/img/logo/Final_CRDS Logo_v2.png";
import logo1 from "../assets/img/logoBIRN.png";
import { FaLocationArrow, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <Container>
            <Row>
              <Col xs={12} md={10}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src={logo}
                          width="150px"
                          height="80px"
                          className="d-inline-block align-top"
                          alt=" logo"
                        />
                        <strong style={{ fontSize: 16 }}>
                          <br />
                          Center for Regional Development Studies (CRDS) <br />
                          Bangladesh University of Engineering and Technology
                          (BUET)
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                         
                        <strong style={{ fontSize: 16 }}>
                          <FaLocationArrow /> 1st Floor, Department of Urban and
                          Regional Planning, BUET, Dhaka-1000, Bangladesh
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                            
                        <strong style={{ fontSize: 16 }}>
                          <FaPhone /> +8801713014801
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                           
                        <strong style={{ fontSize: 16 }}>
                          <MdEmail /> ishratislam@urp.buet.ac.bd
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col xs lg="2" align="right">
                <a
                  rel="noreferrer"
                  href="https://www.buet.ac.bd/web/#/"
                  target="_blank"
                >
                  <img
                    src={logo1}
                    width="150px"
                    className="d-inline-block align-top"
                    alt=" logo"
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="container d-md-flex py-4 footerBottom">
          <div className="me-md-auto text-center text-md-start">
            <div className="credits">
              Developed by
              <Link to="https://www.buet.ac.bd/web/#/ictCell">
                ICT-CEll, BUET
              </Link>
            </div>
          </div>
          <div className="social-links text-center text-md-right pt-3 pt-md-0">
            <Link to="#" className="twitter">
              <i className="bx bxl-twitter"></i>
            </Link>
            <Link to="#" className="facebook">
              <i className="bx bxl-facebook"></i>
            </Link>
            <Link to="#" className="instagram">
              <i className="bx bxl-instagram"></i>
            </Link>
            <Link to="#" className="google-plus">
              <i className="bx bxl-skype"></i>
            </Link>
            <Link to="#" className="linkedin">
              <i className="bx bxl-linkedin"></i>
            </Link>
          </div>
        </div>
      </footer>
      <Link
        to="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </Link>
    </>
  );
}
