import Carousel from "react-bootstrap/Carousel";
import { slideImages } from "../data";

let Slider = () => {
  const slideStyle = {
    height: "500px",
  };

  return (
    <div className="container">
      <Carousel variant="light" fade>
        {slideImages.map((sImage) => {
          const { id, image } = sImage;
          return (
            <Carousel.Item key={id}>
              <img
                className="d-block w-100 img-common"
                style={slideStyle}
                src={image}
                alt="First slide"
              />
              <Carousel.Caption>
                {/* <h5>{caption}</h5>
                <p>{subtitle}</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Slider;
