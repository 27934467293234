import { Outlet } from "react-router-dom";
import { BsReverseListColumnsReverse } from "react-icons/bs";

const SharedNewsLayout = () => {
  return (
    <>
      <h2 className="brown-h1" align="center" style={{ padding: "20px" }}>
        <BsReverseListColumnsReverse /> News and Events
      </h2>
      <Outlet />
    </>
  );
};
export default SharedNewsLayout;
