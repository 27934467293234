import "./App.css";
import HomePage from "./pages/mainPages/HomePage";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SharedLayout, SharedNewsLayout } from "./layouts";
import loadable from "@loadable/component";
//import { AboutUs, Program, Involvement, Links } from "./pages";
//import { NewsList, AllNews, SingleNews } from "./pages/news";

//import Error from "./pages/Error";
//import AddressContact from "./pages/AddressContact";
const AboutUs = loadable(() => import("./pages/AboutUs"));
const Program = loadable(() => import("./pages/Program"));
const Involvement = loadable(() => import("./pages/Involvement"));
const Links = loadable(() => import("./pages/Links"));
const AddressContact = loadable(() => import("./pages/AddressContact"));
const Error = loadable(() => import("./pages/Error"));
const NewsList = loadable(() => import("./pages/news/NewsList"));
const AllNews = loadable(() => import("./pages/news/AllNews"));
const SingleNews = loadable(() => import("./pages/news/SingleNews"));

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<HomePage />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="program/:sectionId" element={<Program />} />
            <Route path="people" element={<Involvement />} />
            <Route path="links" element={<Links />} />
            <Route path="contact" element={<AddressContact />} />
            <Route path="/allNews" element={<AllNews />} />
            <Route path="news" element={<SharedNewsLayout />}>
              <Route index element={<NewsList />} />
              <Route path=":newsId" element={<SingleNews />} />
            </Route>
          </Route>
          <Route path="*" element={<Error />}></Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
