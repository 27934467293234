import React from "react";

const Intro = () => {
  return (
    <div className="testimonial-item">
      <h2 className="brown-h1" align="right">Welcome</h2>
      <p align="justify">
        In order to sustain in the world and to maintain the quality of life,
        human race faces many spatial and sectoral problems. These problems
        requires detailed investigation and calls for further development. To
        undertake the development activities in a planned way, expertise of
        human being is needed. The Center for Regional Development Studies
        (CRDS) works as an excellent Center for developing the expertise of
        Bangladeshi scholars through properly facilitating the spatial and
        sectoral development activities undertaken at local and regional levels
        of Bangladesh.
        <br></br>
        Since the establishment of CRDS back in 2008, it has undertaken several
        researches, organized workshops and trainings with diversified
        development partners. Also, in future it is aiming to extend its
        research work and offer Post Graduate Diploma/ Certificate courses and
        training courses.
      </p>
       
        
       
    </div>
  );
};

export default Intro;
