import image1 from "./assets/img/Dr. Ishrat Islam.jpg";
import image2 from "./assets/img/Dr. Mohammad Shakil Akther.jpg";
import image3 from "./assets/img/Dr. Afsana Haque.jpg";
import image4 from "./assets/img/Dr. Md. Musleh Uddin Hasan.jpg";
import image5 from "./assets/img/Ms. Sadia Afroj.JPG";
import image6 from "./assets/img/Ms. Sumaiya Tabassum.jpeg";

import si1 from "./assets/img/forSlide/b (1).jpeg";
import si2 from "./assets/img/forSlide/b (2).jpeg";
import si3 from "./assets/img/forSlide/b (3).jpeg";
import si4 from "./assets/img/forSlide/b (4).jpg";
import si5 from "./assets/img/forSlide/b (5).jpg";

import image21 from "./assets/img/news1.jpg";
import image31 from "./assets/img/news2.jpg";
import image41 from "./assets/img/news3.jpg";
import image51 from "./assets/img/news4.jpg";
import image61 from "./assets/img/news5.jpg";

export const persons = [
  {
    id: 1,
    name: "Professor Dr. Ishrat Islam",
    designation:
      "<strong>Director</strong> <br /> Center for Regional Development Studies (CRDS) <br /> Bangladesh University Engineering and Technology (BUET)",
    designation1:
      "<strong>Dean </strong><br /> Faculty of Architecture and Planning <br /> Bangladesh University Engineering and Technology (BUET)",
    designation2:
      "<strong>Professor</strong> <br />Department of Urban and Regional Planning (URP)<br /> Bangladesh University Engineering and Technology (BUET)",
    email: "ishratislam@urp.buet.ac.bd",
    image: image1,
    type: 1,
  },
  {
    id: 2,
    name: "Professor Dr. Mohammad Shakil Akhter",
    designation:
      "<strong>Professor </strong><br /> Department of Urban and Regional Planning (URP)",
    email: "shakil@urp.buet.ac.bd,shakil.akther@gmail.com ",
    image: image2,
    type: 1,
  },
  {
    id: 3,
    name: "Professor Dr. Afsana Haque",
    designation:
      "<strong>Professor and Head of the Department </strong><br /> Department of Urban and Regional Planning (URP) <br /> Bangladesh University Engineering and Technology (BUET)",
    email: "afsanahaque@urp.buet.ac.bd",
    image: image3,
    type: 1,
  },
  {
    id: 4,
    name: "Professor Dr. Md. Musleh Uddin Hasan",
    designation:
      "<strong>Professor </strong><br /> Department of Urban and Regional Planning (URP) <br /> Bangladesh University Engineering and Technology (BUET)",
    email: "musleh_uddin@urp.buet.ac.bd",
    image: image4,
    type: 1,
  },

  {
    id: 6,
    name: "Ms. Sadia Afroj",
    designation:
      "<strong>Assistant Professor</strong><br />Department of Urban and Regional Planning (URP) <br /> Bangladesh University Engineering and Technology (BUET)",
    email: "afrojsadia@urp.buet.ac.bd, sadiaafroj76@gmail.com ",
    image: image5,
    type: 2,
  },
  {
    id: 7,
    name: "Ms. Sumaiya Tabassum",
    designation:
      "<strong>Research Assistant </strong><br /> Center for Regional Development Studies (CRDS) <br /> Bangladesh University Engineering and Technology (BUET)",
    email: "sumaiyatabassum747@gmail.com ",
    image: image6,
    type: 3,
  },
];
export const normals = persons.filter((person) => {
  return person.type === 1;
});
export const assiciates = persons.filter((person) => {
  return person.type === 2;
});

export const assistants = persons.filter((person) => {
  return person.type === 3;
});

export const slideImages = [
  { id: 1, image: si1, caption: "Dr. M . A . Rashid Bhaban", subtitle: "" },
  { id: 2, image: si2, caption: "Second slide label", subtitle: "" },
  { id: 3, image: si3, caption: "Third", subtitle: "" },
  { id: 4, image: si4, caption: "Third", subtitle: "" },
  { id: 5, image: si5, caption: "Third", subtitle: "" },
];
export const links = [
  { id: 1, address: "/", text: "home", isMultimenu: false },
  { id: 2, address: "/aboutus", text: "about us", isMultimenu: false },
  { id: 3, address: "/program", text: "program", isMultimenu: true, 
  submenu: [
    { id: 1, address: "/program/1", text: "research projects"},
    { id: 2, address: "/program/2",  text: "Conferences"},
    { id: 3,  address: "/program/3",text: "Training Courses"},
    { id: 4, address: "/program/4", text: "Memorandum Of Understanding"},
  ]},
  { id: 4, address: "/people", text: "People" ,isMultimenu: false},
  { id: 5, address: "/links", text: "Links" ,isMultimenu: false},
  { id: 6, address: "/contact", text: "Contact", isMultimenu: false },
];

export const newsList = [
  {
    id: "8oUs0TJNV1KG1opUJVQzK",
    title:
      "Interactive Workshop on Research Papers for the Edited Book Disappearing Urban Wetlands in Bangladesh: Action Research on Socio-economic Governance and Legal Aspects", 
    link: "https://drive.google.com/file/d/1nX1g-awIScrxVTtyWQxEd2NzczUIc3_q/view",
    image: image61,
    date:"31 May, 2023",
    caption: "",
    text: "",
  },
  {
    id: "8oUs0TJNV1KG1opUJVQzK",
    title:
      "Training of Trainers on Tools and Methods for SDG Localisation in Gazipur", 
    link: "https://drive.google.com/file/d/113_SZMadgg_cfGhNWEPO6MM4rxFh83M1/view",
    image: image51,
    date:"10 September, 2022",
    caption: "",
    text: "",
  },
  {
    id: "8oUs0TJNV1KG1opUJVQzK",
    title:
      "Closing Workshop of the National Adaptation", 
    link: "https://drive.google.com/file/d/14VV0oWGTXfMITn0rmYUwKAu4QgzLIiKF/view",
    image: image21,
    date:"29 November, 2022",
    caption: "",
    text: "",
  },
  {
    id: "O8flW2mMRWOLbuqzM7Lmd",
    title: "Training on Tools and Methods for SDG",
    link: "https://drive.google.com/file/d/1jhi3vPmQwebJC6jcIS6WRYF7oVL8uvGe/view",
    image: image31,
    date:"04 September, 2022",
    caption: "",
    text: "",
  },
  {
    id: "cR07VNw_3kxVzxcu6yEwS",
    title:
      "Stakeholder Workshop on Draft Climate, Gender, and Vulnerability Pre-Assessment (CGVPA) Tool",
    link: "https://drive.google.com/file/d/1hFoEO5j-BFxeC-ooK9CmkEexS9eUgakw/view",
    image: image41,
    date:"16 June, 2022",
    caption: "",
    text: "",
  },
 
];
