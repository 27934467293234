import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { links } from "../data";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState } from "react";
import React from "react";
import SubMenu from "./SubMenu";

const StyledNavbar = () => {
  const [show, setShow] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };



  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="light">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="me-auto ">
            {links.map((link, index) => {
              const { id, address, text, isMultimenu, submenu } = link;
              return (
                <>
                  {!isMultimenu? (
                    <NavLink
                      key={index}
                      to={address}
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link nounderline active"
                          : "nav-link nounderline "
                      }
                    >
                      {text}
                    </NavLink>
                  ): <NavDropdown
                      key={index}
                      title={text}
                      className="nav-link nounderline"
                      id="collasible-nav-dropdown"
                      show={show}
                      onMouseEnter={showDropdown}
                      onMouseLeave={hideDropdown}
                    >
                      <SubMenu key={id} items={submenu} />
                    </NavDropdown>}
                </>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default StyledNavbar;
