import { useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";
const SubMenu = ({ items }) => {
  useEffect(() => {  console.log(items);}, [items]);


  return (
    <>
      {items.map((item, index) => {
        return (
          <NavDropdown.Item key={index}>
            <NavLink className="nav-link nounderlineSubmenu" to={item.address}>
              {item.text}
            </NavLink>
          </NavDropdown.Item>
        );
      })}
    </>
  );
};

export default SubMenu;
