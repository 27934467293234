import { Link } from "react-router-dom";
import image1 from "../../assets/img/Dr. Ishrat Islam.jpg";
import Intro from "../Intro";
import NewsList from "./NewsList";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NewsAndEvent = () => {
  return (
    <section id="testimonials" className="testimonials">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="col-lg-9" data-aos="fade-up">
            <Intro></Intro>
            <br />
            <Container>
              <Row>
                <Col xs={12} md={7}>
                  <h4 className="brown-h1">Message from the Director</h4>
                  <Card border="warning">
                    <Card.Header
                      style={{ color: "brown", background: "#fcf8e2" }}
                    >
                      <div className="row">
                        <div className="col-md-3">
                          <img
                            src={image1}
                            alt="name"
                            className="person-director"
                          />
                        </div>
                        <div className="col-md-9" align="right">
                          <Card.Title>
                            Professor Dr. Ishrat Islam <br />
                            <small>Director</small>
                          </Card.Title>
                          <Card.Text>
                            <b>
                              Center for Regional Development Studies
                              <br></br>
                              Bangladesh University of Engineering & Technology (BUET)
                            </b>
                            <br /> <br />
                          </Card.Text>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text align="justify">
                        Greetings from Center for Regional Development Studies
                        (CRDS), BUET. Research works from this platform focus on
                        designing and implementing innovative and pragmatic
                        solutions to local, regional, national challenges
                        considering socio-political, cultural and economic
                        contexts at global and local scale. We are engaged in
                        research, learning and training that transforms the
                        knowledge, action and leadership needed for more
                        equitable and sustainable development. We work in
                        partnership with wide range of government and
                        non-government organizations from national and global
                        level. We involve academics, policy makers and civil
                        societies to view the challenges from diversified
                        lenses. Our committed team comprised of dedicated and
                        proficient professionals are eager to contribute in
                        research fields towards sustainable development at local
                        and global scale. As Director of the Center, I am
                        honored to lead this team.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12} md={5}>
                  <NewsList />
                  <div>
                    <Link to="/allNews">See all</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NewsAndEvent;
