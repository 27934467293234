import NewsAndEvent from "../news/NewsAndEvent";
import Slider from "../Slider";

export default function HomePage() {
  return (
    <>
      <br />
      <Slider />
      <NewsAndEvent />
    </>
  );
}
